import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

// const { GATSBY_SITE_RECAPTCHA_KEY, GATSBY_SITE_RECAPTCHA_SECRET } = process.env

// TODO: fix CAPTCHA + env vars, it's still breaking the page
// need to re-add the form attrib data-netlify-recaptcha="true"

const ContactForm = () => {
  const [recaptcha, setRecaptcha] = useState(null);

  const onChange = value => {
    setRecaptcha(value);
  };

  return (
    <form
      name="contact-form"
      className="contact-form cf left"
      method="POST"
      data-netlify-recaptcha="true"
      data-netlify="true"
      action="/thanks"
    >
      <input
        type="hidden"
        name="g-recaptcha-response"
        value={recaptcha}
        required="required"
      />
      <input type="hidden" name="form-name" value="contact-form" />
      <div className="form-element">
        <label htmlFor="fieldName">Your Name* </label>
        <input
          className="form-input"
          id="fieldName"
          name="name"
          type="text"
          required="required"
        />
      </div>
      <div className="form-element">
        <label htmlFor="fieldEmail">Your Email Address* </label>
        <input
          className="form-input"
          id="fieldEmail"
          name="email"
          type="email"
          required="required"
        />
      </div>
      <div className="form-element">
        <label htmlFor="fieldCompany">Your Company* </label>
        <input
          className="form-input"
          id="fieldCompany"
          name="company"
          type="text"
          required="required"
        />
      </div>
      <div className="form-element">
        <label htmlFor="fieldTopic">
          What are you interested in chatting about?*{' '}
        </label>
        <select
          className="form-input"
          id="fieldTopic"
          name="topic"
          type="text"
          required="required"
        >
          <option value="Working with us">Working with us</option>
          <option value="Jobs">Jobs</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className="form-element">
        <label htmlFor="fieldMessage">How can we help your team?* </label>
        <textarea
          className="form-input"
          id="fieldMessage"
          name="message"
          type="textarea"
          required="required"
        />
      </div>
      <ReCAPTCHA
        sitekey="6LfoCUgjAAAAACRTitNSzkpUuUq2HSvSyPW0EOdL"
        onChange={onChange}
      />
      <div className="form-element pull-right">
        <button className="button button-primary" type="submit">
          Send
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
